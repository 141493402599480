import React from "react";
import { OneValueRow } from "../post-contract-2.0/PostContractTableModal.components";
import { TitledTable } from "./BenchmarkProjectInfoView";
import { formatValue, formatDate, formatKey } from "./Benchmark.functions";
import isEmpty from "../../validation/is-empty";

export default function BenchmarkNotesView(props) {
  const { rowData } = props;
  const projectNotesKeys = [
    "general_notes",
    "site_specifics",
    "architectural_notes",
    "structural_notes",
    "services_notes",
    "sustainability_notes",
    "schedule_notes",
    "metrics_notes"
  ];

  const combinedRows = {};
  let combinedHeadings = {};
  let combinedValues = {};

  if (isEmpty(rowData)) {
    return null;
  }

  Object.entries(rowData).forEach(([key, value]) => {
    if (key === "id" || key === "metrics") {
      return;
    }

    if (key === "base_date" || key === "compiled_date") {
      value = formatDate(value);
    }

    if (projectNotesKeys.includes(key)) {
      if (!combinedRows[0]) combinedRows[0] = [];
      if (!combinedHeadings[0]) combinedHeadings[0] = [];
      if (!combinedValues[0]) combinedValues[0] = [];

      combinedHeadings[0].push(formatKey(key));
      combinedValues[0].push(formatValue(value));

      combinedRows[0].push(
        <OneValueRow
          key={combinedRows[0].length}
          heading1={combinedHeadings[0]}
          value1={combinedValues[0]}
        />
      );
      combinedHeadings[0] = [];
      combinedValues[0] = [];
    }
  });
  return (
    <div className="create-benchmark-container">
      <div className="create-benchmark-form-container">
        <div className="create-project-panel">
          <TitledTable rows={combinedRows[0]} heading={"Notes"} />
        </div>
      </div>
    </div>
  );
}
