import React, { useState } from "react";
import PropTypes from "prop-types";
import { addAggregation } from "../../api/post-contract/PostContractPackageAPI";
import "./PackageAggregationModal.css";
import isEmpty from "../../validation/is-empty";

AddEditPackageAggregation.propTypes = {
  projectId: PropTypes.string,
  allPackages: PropTypes.object,
  setModal: PropTypes.func,
  selectedPackage: PropTypes.object,
};

export function AddEditPackageAggregation(props) {
  const { projectId, allPackages, setModal } = props;
  const { selectedPackage } = props;

  const [name, setName] = useState(selectedPackage?.Name);
  const [errors, setErrors] = useState({});

  //Get all the packages that are part of the aggregation
  let selectedPackages = allPackages.data.filter((x) =>
    selectedPackage?.AggregatedPackages.includes(x.PackageId)
  );

  // Set selected packages as checked
  const [selectedOptions, setSelectedOptions] = useState(selectedPackages);

  //Get only packages that are not aggregations
  let packages = allPackages.data.filter(
    (pack) => pack.IsAggregation === false
  );

  if (isEmpty(packages)) {
    return null;
  }

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      let selected = selectedOptions.filter((item) => item !== option);
      setSelectedOptions([...selected]);
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  return (
    <div className="aggregation-container">
      <div className="general-row-container">
        <div className="aggregation-name">
          <div className="aggregation-name-field">
            <div className="pc">Aggregation Name </div>
          </div>

          <input
            className="pcf-element-item"
            value={name}
            placeholder={"Enter Aggregation Name"}
            onChange={(e) => {
              setName(e.target.value);
            }}
          ></input>
        </div>
      </div>
      <div className="package-selector-options">
        {packages.map((option) => (
          <button
            key={option.Id}
            className="package-option"
            onClick={() => handleOptionClick(option)}
          >
            <input
              type="checkbox"
              key={option.PackageId}
              value={option.Name}
              checked={selectedOptions.includes(option)}
              onChange={() => handleOptionClick(option)}
            ></input>
            <span className="option-checkbox"></span>
            <div className="option-label">{option.Name}</div>
          </button>
        ))}
      </div>
      <div className="general-row-container">
        <div className="pcu-error-container">
          <div className={errors.type}>
            <b>{errors.text}</b>
          </div>
        </div>
      </div>

      <div className="general-row-container">
        <button
          id={"pcf-table-save-button"}
          className="general-upload-button"
          onClick={() => {
            const data = {
              projectId,
              name,
              allPackages,
              packageIds: selectedOptions.map((option) => option.PackageId),
              packageId: selectedPackage?.PackageId,
              setErrors,
              setModal,
            };
            addAggregation(data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}
