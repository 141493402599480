import React from "react";
import PropTypes from "prop-types";
import { useGetBenchmarkingPortfolioList } from "../../api/portfolio/PortfolioAPI";
import {
  TableHeaderRow,
  TableRow,
  TableSubHeader,
  TitledTable,
} from "./BenchmarkProjectInfoView";
import Spinner from "../common/Spinner";
import "./BenchmarkCollectionView.css";
import isEmpty from "../../validation/is-empty";

BenchmarkCollectionView.propTypes = {
  rowData: PropTypes.object,
  masterData: PropTypes.array,
};
export default function BenchmarkCollectionView(props) {
  const { rowData } = props;
  const { masterData } = props;

  const result = useGetBenchmarkingPortfolioList(rowData.id);

  if (result.benchmarkingPortfolios.loading) {
    return <Spinner />;
  }
  const groupedData = mapBenchmarkProjectData(
    masterData,
    result.benchmarkingPortfolios.data
  );
  const combinedRows = prepareCollectionInfoTable(groupedData, rowData.id);

  return (
    <div className="collection-view-container">
      <div className="create-benchmark-container">
        <div className="create-benchmark-form-container">
          <div className="create-project-panel create-project-matrix-panel custom-margin">
            <TitledTable rows={combinedRows} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function tableCollectionInfo(groupedData) {
  let data = [
    {
      tableHeadergRow: [
        {
          col0: "Id",
          col1: "Project Name",
          col2: "Project Type",
          col3: "Quality",
          col4: "Sector",
          col5: "Sub Sector",
          col6: "Standard",
        },
      ],
    },
  ];
  let indexCounter = 0;
  Object.entries(groupedData).forEach(
    ([collectionName, projects], colIndex) => {
      data[0][`subHeading${colIndex}`] = [
        {
          col0: "",
          col1: "subheading",
          col2: "",
          col3: collectionName,
          col4: "",
          col5: "",
          col6: "",
        },
      ];

      projects.forEach((project) => {
        data[0][`project${indexCounter++}`] = [
          {
            col0: project.id,
            col1: project.project_name,
            col2: project.project_type,
            col3: project.quality,
            col4: project.sector,
            col5: project.sub_sector,
            col6: project.standard,
          },
        ];
      });
    }
  );

  return data;
}

export function mapBenchmarkProjectData(masterData, result) {
  if (isEmpty(masterData) || isEmpty(result)) return {};

  const collectionProjects = result.map((data) => ({
    collectionId: data.id,
    name: data.name,
    projectIds: data.projectIds,
  }));
  let finalData = [];
  collectionProjects.forEach((element) => {
    element.projectIds.forEach((projectId) => {
      const benchmarkProject = masterData.find((row) => row.id === projectId);

      if (benchmarkProject) {
        const {
          id,
          project_name,
          sector,
          sub_sector,
          project_type,
          standard,
          quality,
        } = benchmarkProject;

        finalData.push({
          id,
          project_name,
          sector,
          sub_sector,
          project_type,
          standard,
          quality,
          collection_id: element.collectionId,
          collection_name: element.name,
        });
      }
    });
  });

  const groupedData = finalData.reduce((acc, item) => {
    if (!acc[item.collection_name]) {
      acc[item.collection_name] = [];
    }
    acc[item.collection_name].push(item);
    return acc;
  }, {});

  return groupedData;
}

export function prepareCollectionInfoTable(rowData, selectedBenchmarkId) {
  if (isEmpty(rowData)) return [];

  let combinedRows = [];
  const tableConfigs = {
    columns: {
      col0: { visible: false },
      col1: { visible: true },
      col2: { visible: true },
      col3: { visible: true },
      col4: { visible: true },
      col5: { visible: true },
      col6: { visible: true },
    },
    rows: {
      subheading: { visible: true },
    },
    cells: {},
  };
  let hasHeader = true;
  tableCollectionInfo(rowData).forEach((element) => {
    Object.entries(element).forEach(([key, value]) => {
      Object.entries(value).forEach((row) => {
        if (hasHeader) {
          combinedRows.push(
            <TableHeaderRow
              key={row[1].col0 + `${combinedRows.length}`}
              itemData={row[1]}
              config={tableConfigs}
            ></TableHeaderRow>
          );
          hasHeader = false;
        } else if (row[1].col1 === "subheading") {
          combinedRows.push(
            <TableSubHeader subHeading={row[1].col3} config={tableConfigs} />
          );
        } else {
          selectedBenchmarkId !== row[1].col0
            ? combinedRows.push(
                <TableRow
                  key={`tr-${combinedRows.length}`}
                  itemData={row[1]}
                  config={tableConfigs}
                ></TableRow>
              )
            : combinedRows.push(
                <div className="selected-benchmark-collection-row">
                  <TableRow
                    key={`tr-${combinedRows.length}`}
                    itemData={row[1]}
                    config={tableConfigs}
                  ></TableRow>
                </div>
              );
        }
      });
    });
  });
  combinedRows = combinedRows.filter((filledrows) => filledrows != null);
  return combinedRows;
}
