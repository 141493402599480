import React, { useState } from "react";
import PropTypes from "prop-types";

import { tranStr } from "../../utils/translation";

import "./BenchmarkingDisplayTable.css";
import Table from "../table/Table";
import isEmpty from "../../validation/is-empty";
import {
  generateTotal,
  GetWarning,
  highLightFirstRow,
  Toggle,
} from "./BenchmarkingDisplayTable.functions";

/// props validation | SQ(javascript:S6774)
BenchmarkingDisplayTable.propTypes = {
  skipHighlight: PropTypes.bool,
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
  isEscalated: PropTypes.bool,
  setIsEscalated: PropTypes.func,
  unitOfMeasure: PropTypes.string,
  country: PropTypes.string,
  isLocalRegionFactorEnabled: PropTypes.bool,
  caller: PropTypes.string,
  isGlobalBenchmarking: PropTypes.bool,
  moduleId: PropTypes.string,
};
///
export default function BenchmarkingDisplayTable(props) {
  const { skipHighlight } = props;
  const { thisBenchmark, benchmarks } = props;
  const { isEscalated, setIsEscalated } = props;
  const { unitOfMeasure } = props;
  const { country, isLocalRegionFactorEnabled, caller } = props;
  const { isGlobalBenchmarking } = props;
  const { moduleId } = props;
  const [selectedRow, setSelectedRow] = useState({});

  const isFiltered = false;
  const isTotalled = true;
  const maxFilterCols = 11;

  const bmSummary = [thisBenchmark].concat(benchmarks);

  bmSummary.forEach((bm) => {
    if (!isEmpty(bm) && !isEmpty(bm.metrics)) {
      let metric = bm.metrics.filter((x) => x.code === "D1.5");
      if (!isEmpty(metric)) {
        bm.local_region_area_unit = metric[0]?.unit;
      }
    }
  });

  const [seconds, setSeconds] = useState(0);

  highLightFirstRow(skipHighlight, caller, seconds, setSeconds);

  function columns(selectedColumns) {
    const columns = [
      {
        heading: tranStr("Project Name"),
        key: "project_name",
        type: "IMAGE",
        width: 250,
        isFiltered: isFiltered,
        customComponent: (value) => {
          return (
            <GetWarning
              benchmark={bmSummary.find((item) => item.project_name === value)}
              isGlobalBenchmarking={isGlobalBenchmarking}
              skipHighlight={skipHighlight}
              country={country}
              cellText={value}
            />
          );
        },
      },
      {
        heading: tranStr("Base Date"),
        key: "base_date",
        type: "DATE",
        width: 90,
        isFiltered: isFiltered,
      },
      {
        heading: tranStr("Local Region Area Unit"),
        key: "local_region_area_unit",
        type: "TEXT",
        width: 90,
        isFiltered: isFiltered,
      },
      {
        heading: tranStr("GIA per m2"),
        key: "gia",
        type: "NUMBER",
        width: 130,
        isFiltered: isFiltered,
        isTotalled: { isTotalled },
        generateTotal: (bms, cellKey) => {
          return generateTotal(bms, cellKey);
        },
      },
      {
        heading: tranStr("Local Region Area"),
        key: "local_region_area",
        type: "NUMBER",
        width: 90,
        isFiltered: isFiltered,
      },
      {
        heading:
          (isLocalRegionFactorEnabled && caller !== "COST_PLANNING"
            ? tranStr("Cost/Local Region Area ")
            : tranStr("Construction Cost") + "/" + unitOfMeasure) +
          "@" +
          " " +
          tranStr("Estimate Date"),
        key: "exchanged_calculated_construction_cost_rate",
        type: "NUMBER",
        width: 90,
        isFiltered: isFiltered,
        isTotalled: { isTotalled },
        generateTotal: (bms, cellKey) => {
          return generateTotal(bms, cellKey);
        },
      },
      {
        heading:
          (isLocalRegionFactorEnabled && caller !== "COST_PLANNING"
            ? tranStr("Cost/Local Region Area ")
            : tranStr("Construction Cost") + "/" + unitOfMeasure) +
          " " +
          tranStr("Escalated"),
        key: "escalated_calculated_construction_cost_rate",
        type: "NUMBER",
        width: 90,
        isFiltered: isFiltered,
        isTotalled: { isTotalled },
        generateTotal: (bms, cellKey) => {
          return generateTotal(bms, cellKey);
        },
      },

      {
        heading: tranStr("Procurement Method"),
        key: "procurement_method",
        type: "TEXT",
        width: 180,
        isFiltered: isFiltered,
      },
      {
        heading: tranStr("Type"),
        key: "project_type",
        type: "TEXT",
        width: 130,
        isFiltered: isFiltered,
      },
      {
        heading: tranStr("Quality"),
        key: "quality",
        type: "TEXT",
        width: 90,
        isFiltered: isFiltered,
      },
      {
        heading: tranStr("Sector"),
        key: "sector",
        type: "TEXT",
        width: 200,
        isFiltered: isFiltered,
      },
      {
        heading: tranStr("Project Stage"),
        key: "project_stage",
        type: "TEXT",
        width: 200,
        isFiltered: isFiltered,
      },
      {
        heading: tranStr("City"),
        key: "tpi_city",
        type: "TEXT",
        width: 200,
        isFiltered: isFiltered,
      },
      {
        heading: tranStr("Program Duration"),
        key: "program_duration",
        type: "NUMBER",
        width: 90,
        isFiltered: isFiltered,
      },
      {
        heading: tranStr("Form of Contract"),
        key: "form_of_contract",
        type: "TEXT",
        width: 200,
        isFiltered: isFiltered,
      },
    ];
    if (isEmpty(selectedColumns)) {
      return columns;
    }
    let columnsToBeReturned = columns.filter(function (el) {
      return selectedColumns.indexOf(el.key) >= 0;
    });

    return columnsToBeReturned;
  }

  return (
    <div className="bm_display_table">
      <Toggle
        isEscalated={isEscalated}
        setIsEscalated={setIsEscalated}
        caller={caller}
      />
      <Table
        title={tranStr("Benchmarking Display Table")}
        tableArray={bmSummary}
        columns={columns()}
        tableSize={bmSummary.length}
        isTitleDisabled={true}
        isTotalDisabled={false}
        isPaginationDisabled={true}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        discriminator={caller}
        moduleId={moduleId}
        isMenuAvailable={caller === "MASTER_BENCHMARKING"}
        maxFilterCols={maxFilterCols}
      />
    </div>
  );
}
