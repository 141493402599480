import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import isEmpty from "../../validation/is-empty";

// API
import { pc2APIs } from "../api-endpoints/PostContract2.0Endpoints";

export function usePackages(projectId) {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    setLoading(true);
    getPackages(projectId)
      .then((data) => {
        setPackages(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [projectId]);

  return {
    packages: {
      data: packages,
      loading: loading,
      get: getPackages,
      set: setPackages,
    },
  };
}

export async function getPackages(projectId) {
  // POST CONTRACT API
  const url = pc2APIs().read_packages + "?projectId=" + projectId;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
    //Add new get here
  } catch (e) {
    console.log("getPackages api error");
  }

  if (response.ok) {
    response = await response.json();

    const packageData = [];
    response.packageFields.forEach((parent) => {
      parent.options.forEach((row) => {
        const pack = {
          Id: row.id,
          PackageName: row.options.find((x) => x.name === "Package Name").value,
          ContractType: row.options.find((x) => x.name === "Contract Type")
            .value,
          ProjectId: projectId,
          HasContractFields: row.value.toLowerCase() === "true",
        };
        packageData.push(pack);
      });
    });

    response = packageData;
  } else {
    response = [];
    console.log("getPackages response error");
  }
  return response;
}

//Get aggregate packages
export function useAggregatePackages(projectId) {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAggregatePackages(projectId)
      .then((data) => {
        if (!isEmpty(data) && !isEmpty(data.packages)) {
          setPackages(data.packages);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [projectId]);

  return {
    allPackages: {
      data: packages,
      loading: loading,
      get: getAggregatePackages,
      set: setPackages,
    },
  };
}

export async function getAggregatePackages(projectId) {
  // POST CONTRACT API
  const url = pc2APIs().get_aggregate_packages + "?projectId=" + projectId;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
    //Add new get here
  } catch (e) {
    console.log("getPackages api error");
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log("getPackages response error");
  }
  return response;
}

export async function addAggregation(data) {
  const { setModal, allPackages } = data;
  const { setErrors, packageId } = data;
  const { packageIds, projectId, name } = data;

  if (name === "") {
    return setErrors({
      text: "Please Enter Aggregation Name",
      type: "feedback-error",
    });
  }
  if (packageIds.length === 0) {
    return setErrors({
      text: "Please select at least one package",
      type: "feedback-error",
    });
  }

  setErrors({
    text: "Saving...",
    type: "feedback-success",
  });

  let aggregation = {
    packageId: packageId,
    projectId: projectId,
    name: name,
    packageIds: packageIds,
  };

  let url = pc2APIs().save_aggregation;

  const config = {
    method: "POST",
    body: JSON.stringify(aggregation),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("Save Aggregation Error");
  }

  if (response.ok) {
    //updat the packages
    let packageData = await allPackages.get(projectId);
    await allPackages.set(packageData.packages);

    setErrors({
      text: "Created Successfully",
      type: "feedback-success",
    });

    setModal(false);
  } else {
    return setErrors({
      text: "Save Error",
      type: "feedback-error",
    });
  }
}

export async function deleteAggregation(data) {
  const { allPackages } = data;
  const { packageId } = data;
  const { projectId } = data;

  let url =
    pc2APIs().delete_aggregation +
    "?projectId=" +
    projectId +
    "&packageId=" +
    packageId;

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("Save Aggregation Error");
  }

  if (response.ok) {
    //update the packages
    let packageData = await allPackages.get(projectId);
    await allPackages.set(packageData.packages);
  }
  return true;
}
