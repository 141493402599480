import React, { useState, useEffect } from "react";

import Spinner from "../common/Spinner";
import PropTypes from "prop-types";
import isEmpty from "../../validation/is-empty";
import "./PostContractPackageSelector.css";

PostContractPackageSelector.propTypes = {
  packages: PropTypes.object,
  selectedPackage: PropTypes.object,
  setSelectedPackage: PropTypes.func,
};

export function PostContractPackageSelector(props) {
  const { packages } = props;
  const { selectedPackage, setSelectedPackage } = props;
  const [isOpen, setIsOpen] = useState(false);

  // Set Default Package
  useEffect(() => {
    if (!isEmpty(packages.data && packages.data.length > 0)) {
      setSelectedPackage(packages.data[0]);
    }
  }, [setSelectedPackage, packages.data]);

  if (packages.loading) {
    return <Spinner width={50} />;
  }

  if (isEmpty(selectedPackage) || isEmpty(selectedPackage.PackageName)) {
    return null;
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedPackage(option);
    setIsOpen(false);
  };

  const display = [];

  packages.data.forEach((element) => {
    let clsName = "package-option-item";
    if (element.Id === selectedPackage.Id) {
      clsName = "package-option-selected";
    }
    display.push(
      <button
        key={element.Id}
        className={clsName}
        onClick={() => handleOptionClick(element)}
      >
        <div className="option-label">{element.PackageName}</div>
      </button>
    );
  });

  return (
    <div className="package-selector-container">
      <button className="package-selector-dropdown" onClick={toggleDropdown}>
        {!isEmpty(selectedPackage)
          ? selectedPackage.PackageName
          : "Select an option"}
        <div className="package-selector-dropdown-icon">
          {isOpen && <i className="fas fa-chevron-up"></i>}
          {!isOpen && <i className="fas fa-chevron-down"></i>}
        </div>
      </button>
      {isOpen && (
        <div className="package-selector-dropdown-options">{display}</div>
      )}
    </div>
  );
}
