// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import { TableHeadings } from "./TableHeadings";
import { TableBody } from "./TableBody";
import { TableTotal, TableFilteredTotal } from "./TableTotal";
import TablePagination from "./TablePagination";
import TableFilters from "./TableFilters";

// Functions
import { filterData } from "./Table.functions";

// Style
import "./Table.css";
import isEmpty from "../../validation/is-empty";
import TableMenuOptions from "./TableMenuOptions";
import Modal from "../modal/Modal";
import { translate, tranStr } from "../../utils/translation";

TableWrapper.propTypes = {
  tableArray: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.string,
  isTitleDisabled: PropTypes.bool,
  isTotalDisabled: PropTypes.bool,
  isFilterDisabled: PropTypes.bool,
  isPaginationDisabled: PropTypes.bool,
  tableSize: PropTypes.number,
  // Selected Rows
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func,
  isClickToClose: PropTypes.bool,
  //Selected Menu Columns
  setSelectedMenuColumns: PropTypes.func,
  // Modals
  setModal: PropTypes.func,
  // Checkboxes
  checked: PropTypes.array,
  setChecked: PropTypes.func,

  configureSettings: PropTypes.object,
  moduleId: PropTypes.string,
  discriminator: PropTypes.string,
  isMenuAvailable: PropTypes.bool,
  maxFilterCols: PropTypes.number,
};
export default function TableWrapper(props) {
  const { tableArray } = props;
  const { columns } = props;
  const { title } = props;
  const { tableSize } = props;

  const { isTitleDisabled } = props;
  const { isTotalDisabled } = props;
  const { isFilterDisabled } = props;
  const { isPaginationDisabled } = props;
  const { maxFilterCols } = props;

  // Selected Rows
  const { selectedRow, setSelectedRow } = props;
  const { isClickToClose } = props;
  // Selected Menu Columns
  const { setSelectedMenuColumns } = props;
  // Modals
  const { setModal } = props;
  // Checkboxes
  const { checked, setChecked } = props;

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(tableSize || 10);
  const [filters, setFilters] = useState([]);

  ///changed from here
  const { configureSettings, moduleId, discriminator, isMenuAvailable } = props;
  const [tableMenuModal, setTableMenuModal] = useState(false);

  let filteredTableColumns =
    configureSettings.configureSettings.data[0]?.configSettings?.table?.find(
      (tab) => tab.discriminator === title
    )?.Columns;

  let columnsToBedisplayed = columns?.filter((col) =>
    filteredTableColumns?.includes(col.key)
  );

  const [selectedColumns, setSelectedColumns] = useState(
    isEmpty(filteredTableColumns)
      ?  columns.slice(0,maxFilterCols?? columns.length).map((col) => col.key)
      : filteredTableColumns
  );
  const [displayColumns, setDisplayColumns] = useState(
    isEmpty(columnsToBedisplayed) ? columns.slice(0,maxFilterCols?? columns.length) : columnsToBedisplayed.slice(0,maxFilterCols?? columns.length)
  );
  if (!isEmpty(setSelectedMenuColumns)) setSelectedMenuColumns(displayColumns);
  ///changed till here

  let filterColumns = columns.filter((p) => p.isFiltered);
  let filteredData = filterData(tableArray, filters, filterColumns);

  // If the table data or filters change reset back to page 1
  useEffect(() => {
    setActivePage(1);
  }, [filters]);

  // Calculate start and end item indexes for the page
  let startIndex = (activePage - 1) * pageSize;
  // Multiplying by 1 to convert string to number
  let endIndex = Math.min(
    startIndex + pageSize * 1 - 1,
    filteredData.length - 1
  );

  let pageData = filteredData.slice(startIndex, endIndex + 1);

  return (
    <div className="pct-container">
      <div className="pct">
        {!isTitleDisabled && (
          <div className="pct-title">
            <div className="display-4">
              <b>{translate(title)}</b>
            </div>
          </div>
        )}
        <div className="pct-headings-container">
          <TableMenu
            setTableMenuModal={setTableMenuModal}
            isMenuAvailable={isMenuAvailable}
          />
          <TableHeadings columns={displayColumns} />
          <TableFilters
            columns={displayColumns}
            setFilters={setFilters}
            filters={filters}
            tableArray={tableArray}
            isFilterDisabled={isFilterDisabled}
          />
        </div>
        <TableBody
          pageSize={pageSize}
          columns={displayColumns}
          tableArray={pageData}
          setModal={setModal}
          // Select Rows
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          isClickToClose={isClickToClose}
          // Check Boxes
          checked={checked}
          setChecked={setChecked}
        />
        <TableFilteredTotal
          columns={displayColumns}
          tableArray={filteredData}
          filters={filters}
          isTotalDisabled={isTotalDisabled}
        />
        <TableTotal
          columns={displayColumns}
          tableArray={pageData}
          isTotalDisabled={isTotalDisabled}
        />
      </div>
      {!isPaginationDisabled && (
        <div className="pct-pagination">
          <TablePagination
            activePage={activePage}
            setActivePage={setActivePage}
            totalItems={filteredData.length}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageData={pageData.length}
          />
          <div className="pct-button-spacer" />
        </div>
      )}

      <Modal
        // Modal Props
        title={tranStr(title) + " Column Options"}
        Component={TableMenuOptions}
        modal={tableMenuModal}
        setModal={setTableMenuModal}
        // Component Props
        columns={columns}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        setDisplayColumns={setDisplayColumns}
        configureSettings={configureSettings}
        tableDiscriminator={title}
        discriminator={discriminator}
        moduleId={moduleId}
        maxFilterCols={maxFilterCols}
      />
    </div>
  );
}

TableMenu.propTypes = {
  setTableMenuModal: PropTypes.func,
  isMenuAvailable: PropTypes.bool,
};
export function TableMenu(props) {
  const { setTableMenuModal } = props;
  const { isMenuAvailable } = props;

  if (isEmpty(isMenuAvailable) || !isMenuAvailable) {
    return null;
  }

  return (
    <button
      value="Menu"
      className="table-options-cog-menu"
      onClick={() => setTableMenuModal(true)}
    >
      <i className="fas fa-th"></i>
    </button>
  );
}
