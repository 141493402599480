// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Table from "../table/Table";
import BenchmarkView from "../benchmarking/BenchmarkView";
import Modal from "../modal/Modal";

// Functions
import { tranStr } from "../../utils/translation";
import { columns } from "./BenchmarkTableComponent.functions";

import "./BenchmarkingTable.css";

BenchmarkingTable.propTypes = {
  data: PropTypes.array,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
  user:PropTypes.object
};

export default function BenchmarkingTable(props) {
  const { data } = props;
  const { selection, setSelection ,user} = props;

  // Table Row Selection
  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  return (
    <div>
      <Table
        title={tranStr("Cost Plan Table")}
        tableArray={data}
        columns={columns()}
        tableSize={14}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Checked Selection
        checked={selection}
        setChecked={setSelection}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        modal={modal}
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={"Selected Item"}
        Component={BenchmarkView}
        modal={modal}
        setModal={setModal}
        columns={columns}
        // Component Props
        selectedRow={selectedRow}
        user={user}
        masterData = {data}
      />
    </div>
  );
}
