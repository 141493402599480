import { detectDeployment } from "./Utilities";

export function feedbackAPIs() {
  const config = {
    serviceAPI: true,
    localService: "https://dev2.cost-clarity.com/apis/sharedservice/api",
    deployedService: "/apis/sharedservice/api",
  };

  // Local dev https://localhost:44320/api
  // Deployed devhttps://dev.cost-clarity.com/apis/sharedservice/api

  const D = detectDeployment(config);

  return {
    //  Feedback
    create_feedback: `${D}/FormHandler/create_feedback?api-version=1.0`,
    get_feedback: `${D}/FormHandler/get_feedback?api-version=1.0`,
    delete_feedback: `${D}/FormHandler/delete_feedback?api-version=1.0`,
    create_feedback_note: `${D}/FormHandler/create_feedback_note?api-version=1.0`,
    update_feedback_incomplete: `${D}/FormHandler/update_feedback_incomplete?api-version=1.0`,
    update_feedback_complete: `${D}/FormHandler/update_feedback_complete?api-version=1.0`,
  };
}

export function contactUsApis() {
  const config = {
    serviceAPI: true,
    localService: "https://dev2.cost-clarity.com/apis/sharedservice/api",
    deployedService: "/apis/sharedservice/api",
  };

  const D = detectDeployment(config);

  // Local dev https://localhost:44320/api
  // Deployed devhttps://dev.cost-clarity.com/apis/sharedservice/api

  return {
    //  Feedback
    get_demos: `${D}/FormHandler/get_demos?api-version=1.0`,
    delete_demo_request: `${D}/FormHandler/delete?api-version=1.0`,
    create_demo_request: `${D}/FormHandler/submit?api-version=1.0`,
    update_demo_contacted: `${D}/FormHandler/Contacted?api-version=1.0`,
    update_demo_completed: `${D}/FormHandler/Completed?api-version=1.0`,
  };
}
