import React from "react";
import isEmpty from "../../validation/is-empty";
import {
  headerTitles,
  Cell,
  Row,
  Table,
  groupBy,
} from "./BenchmarkElementAnalysisView.functions";
import "./BenchmarkElementAnalysisView.css";
import PropTypes from "prop-types";
import { formatNumber } from "./Benchmark.functions";

const PROJECT_COST_CATEGORY_KEY ="Project Cost";
const CONSTRUCTION_COST_CATEGORY_KEY ="Construction Cost";

BenchmarkElementAnalysisView.propTypes = {
  rowData: PropTypes.object,
};

export function BenchmarkElementAnalysisView(props) {
  const { rowData } = props;
  let headerRow = [];
  let rows = [];
  if (
    isEmpty(rowData) ||
    isEmpty(rowData.category_groups) ||
    rowData.rows.length === 0
  ) {
    return (
      <div className="no-data-available">
        <i className="fas fa-thumbs-down" /> No Elemental Data Available
      </div>
    );
  }

  //get All codes from category groups
  prepareElementalAnalysisTable(rowData, headerRow, rows);

  return <Table rows={rows} headerRow={headerRow}></Table>;
}

export function prepareElementalAnalysisTable(rowData, headerRow, rows) {
  let ui = 0;
  let cells = [];
  if (isEmpty(rowData) || isEmpty(rowData.category_groups)) {
    return null;
  }
  const allcodes = rowData.category_groups
    .map((group) =>
      group.categoryItems.map((items) =>
        items.codes?.map((code) => [
          { code: code, field: items.field, category: group.categoryName },
        ])
      )
    )
    .flat(3);

  //Filter Element Categories from allCodes retrieved from above step
  const elementItems = allcodes.filter(
    (co) =>
      co?.category === "Element Categories" ||
      co?.category === "Cost Categories" ||
      co?.category === "Cost Type"
  );

  // finding tax item
  const keyCategories = rowData.category_groups?.find(
    (g) => g.categoryName === "Key Categories"
  );
  const taxItem = keyCategories?.categoryItems?.find((g) => g.field === "Tax");

  //insert corresponding category for each row in rowData.rows collection by using the elementItems collection filtered from the above step
  rowData.rows.forEach((row) => {
    let categoryItems = elementItems.filter(
      (filtered) => filtered.code === row["code"]
    );

    row["category"] = categoryItems[0]?.field;
    row["tradeCategory"] = categoryItems[1]?.field;
    row["constructionCategory"] = categoryItems[2]?.field;
  });

  //Prepare Groups based on code in rowData rows
  let allCatgeoryResult = groupBy(rowData.rows, "constructionCategory");

  // Prepare Header Row and push to row object
  cells.push(
    Object.entries(headerTitles).map(([key, value]) => {
      let className = "info-table-cell header " + value.type;
      return (
        <Cell
          key={ui++}
          content={value.content}
          cellSpecificClass={className}
          isReporting={value.isReport}
        ></Cell>
      );
    })
  );
  headerRow.push(<Row key="header" Cells={cells}></Row>);

  let showTotal = [];
  //loop of super parent
  Object.entries(allCatgeoryResult).forEach((spGroup, index) => {
    let tradeCategoryGroup = groupBy(spGroup[1], "tradeCategory");
    Object.entries(tradeCategoryGroup).forEach((pGroup, index) => {
      let result = groupBy(pGroup[1], "category");
      addRows(result,headerTitles);
      //Prepare Sum for each column under a Category group and prepare cells and push it to a sub heading total row.
      cells = prepareTotalsRow(pGroup, "tradeCategory", "sub-total", taxItem);
      //check whether total row should be shown or not based on the values that row has all (currency and number fields) zero values
      showTotal.some((k) => k === true) &&
        rows.push(<Row key={ui++} Cells={cells} rowSpecificClass={""}></Row>);
    });
    //add super parent category total
    //Prepare Sum for each column under a Category group and prepare cells and push it to a sub heading total row.
    cells = prepareTotalsRow(
      spGroup,
      "constructionCategory",
      "grand-total",
      taxItem
    );
  });
 
 // prepare row for construction cost including tax
 cells = prepareCost(CONSTRUCTION_COST_CATEGORY_KEY,taxItem);
 showTotal.some((k) => k === true) &&
   rows.push(<Row key={ui++} Cells={cells} rowSpecificClass={""}></Row>);

 // prepare row for project cost including tax
 cells = prepareCost(PROJECT_COST_CATEGORY_KEY ,taxItem);
 showTotal.some((k) => k === true) &&
    rows.push(<Row key={ui++} Cells={cells} rowSpecificClass={""}></Row>);

 // prepare row for project cost including tax
 cells = prepareProjectCostIncludingTax(taxItem);
 showTotal.some((k) => k === true) &&
    rows.push(<Row key={ui++} Cells={cells} rowSpecificClass={""}></Row>);
 
    
  
  /** Internal functions section  */

  function addRows(groupResult,headerTitles){
    Object.entries(groupResult).forEach((group, index) => {
      //Prepare Cell Content for Each column and push it to Row Array
      group[1].forEach((element, rowIndex) => {
        cells = [];
        cells.push(
          getCell(headerTitles,element)
        );
        //check whether row should be shown or not based on the values that row has all (currency and number fields) zero values
        //hasData = Object.values(element).filter((k) => k === 0).length < 5;
        showTotal.push(hasData(element));
        hasData &&
          rows.push(
            <Row key={ui++} Cells={cells} rowSpecificClass={""}></Row>
          );
      });

      //Prepare Sum for each column under a Category group and prepare cells and push it to a sub heading total row.
      cells = prepareTotalsRow(group, "category", "");

      //check whether total row should be shown or not based on the values that row has all (currency and number fields) zero values
      showTotal.some((k) => k === true) &&
        rows.push(<Row key={ui++} Cells={cells} rowSpecificClass={""}></Row>);
    });
  }

  function hasData(element){
    return Object.values(element).filter((k) => k === 0).length < 5;
  }
  
  function getCell(headerTitles, element){
    return Object.entries(headerTitles).map(([key, value]) => {
      let cellContent = "";
      cellContent = formatNumber(value.type, element[`${key}`]);
      return (
        <Cell
          key={ui++}
          content={cellContent}
          cellSpecificClass={value.type}
          isReporting={value.isReport}
        ></Cell>
      );
    })
  }
  
  function prepareTotalsRow(group, categoryKey, aggregationType, taxItem) {
    let cells = [];
    let headerStyle;
  
    // Determine header style based on aggregation type
    switch (aggregationType) {
      case "sub-total":
        headerStyle = "subtotal-subheading ";
        break;
      case "grand-total":
        headerStyle = "grandtotal-subheading ";
        break;
      default:
        headerStyle = "total-subheading ";
        break;
    }
  
    // Process each header title
    Object.entries(headerTitles).forEach(([key, value]) => {
      const className = `info-table-cell ${headerStyle}${value.type}`;
      let spSum = 0;
      let category = "";
  
      // Calculate sum for the current group
      group[1].forEach((item) => {
        if (value.type === "number" || value.type === "currency") {
          spSum += Number(item[key]);
        }
        category = item[categoryKey];
      });
  
      // Get the total project cost or construction cost
      const total = getProjectCostOrConstructionCost(category, key, taxItem);
      spSum = total === 0 ? spSum : total;
  
      // Push either a description or formatted value to cells
      if (value.type === "description") {
        cells.push(
          <Cell
            key={ui++}
            content={
              isEmpty(category)
                ? `${aggregationType.toUpperCase()} Total`
                : `${aggregationType.toUpperCase()} ${category} Total`
            }
            cellSpecificClass={className}
            isReporting={value.isReport}
          />
        );
      } else {
        cells.push(
          <Cell
            key={ui++}
            content={
              isNaN(spSum) || ['code', 'reserved', 'description'].includes(key) ? "" : formatNumber(value.type, spSum)
            }
            cellSpecificClass={className}
            isReporting={value.isReport}
          />
        );
      }
    });
  
    return cells;
  }
  
  function prepareProjectCostIncludingTax(taxItem) {
    let cells = [];
  
    // Process each header title
    Object.entries(headerTitles).forEach(([key, value]) => {
      const className = `info-table-cell grandtotal-subheading ${value.type}`;
      let spSum = 0;
      const category = `${PROJECT_COST_CATEGORY_KEY} (tax inclusive)`;
  
      // Get the total project cost or construction cost (tax inclusive)
      const total = getProjectCostOrConstructionCost(
        PROJECT_COST_CATEGORY_KEY,
        key,
        taxItem
      );
      spSum = total === 0 ? spSum : total;

      // Push either a description or formatted value to cells
      if (value.type === "description") {
        cells.push(
          <Cell
            key={ui++}
            content={isEmpty(category) ? "Total" : `${category}`}
            cellSpecificClass={className}
            isReporting={value.isReport}
          />
        );
      } else {
        cells.push(
          <Cell
            key={ui++}
            content={
              isNaN(spSum) || ['code', 'reserved', 'description'].includes(key) ? "" : formatNumber(value.type, spSum)
            }
            cellSpecificClass={className}
            isReporting={value.isReport}
          />
        );
      }
    });
  
    return cells;
  }

  function prepareCost(categoryKey, taxItem) {
    let cells = [];
  
    // Process each header title
    Object.entries(headerTitles).forEach(([key, value]) => {
      const className = `info-table-cell grandtotal-subheading ${value.type}`;
      let spSum = 0;
      const category = `${categoryKey} `;
  
      // Get the total project cost or construction cost (tax inclusive)
      const total = getProjectCostOrConstructionCost(
        categoryKey,
        key,
        taxItem
      );
      spSum = total === 0 ? spSum : total;

      // Push either a description or formatted value to cells
      if (value.type === "description") {
        cells.push(
          <Cell
            key={ui++}
            content={isEmpty(category) ? "Total" : `${category} Total`}
            cellSpecificClass={className}
            isReporting={value.isReport}
          />
        );
      } else {
        cells.push(
          <Cell
            key={ui++}
            content={
              isNaN(spSum) || ['code', 'reserved', 'description'].includes(key) ? "" : formatNumber(value.type, spSum)
            }
            cellSpecificClass={className}
            isReporting={value.isReport}
          />
        );
      }
    });
  
    return cells;
  }

  

  function getProjectCostOrConstructionCost(category, key, taxItem) {
    let spSum = 0;
    if (category === PROJECT_COST_CATEGORY_KEY || category === CONSTRUCTION_COST_CATEGORY_KEY) {
      if (key === "total") {
        spSum =
          category === PROJECT_COST_CATEGORY_KEY
            ? rowData.project_cost
            : rowData.construction_cost;
        spSum += taxItem.value;
      } else if (key === "gia_rate") {
        spSum = rowData.gia;
        spSum += taxItem.giaRate;
      } else if (key === "local_region_rate") {
        spSum = rowData.local_region_area;
        spSum += taxItem.localRegionRate;
      } else if (key === "rate") {
        spSum =
          category === PROJECT_COST_CATEGORY_KEY
            ? rowData.project_cost_rate
            : rowData.construction_cost_rate;
      }
    }
    return spSum;
  }
}
