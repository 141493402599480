import { detectDeployment } from "./Utilities";

export function bmAPIs() {
  const config = {
    serviceAPI: true,
    localService:
      // "https://localhost:44382/v1.0",
      "https://dev2.cost-clarity.com/postcontract/apis/benchmarkingservice/v1.0",
    deployedService: "/postcontract/apis/benchmarkingservice/v1.0",
  };

  // Deployed local "https://localhost:44382/v1.0"
  // Deployed dev   "https://dev.cost-clarity.com/postcontract/apis/benchmarkingservice/v1.0"
  // Deployed test  "https://test.cost-clarity.com/postcontract/apis/benchmarkingservice/v1.0"
  // Deployed prd   "https://www.cost-clarity.com/postcontract/apis/benchmarkingservice/v1.0"

  const D = detectDeployment(config);

  return {
    // Create Benchmarks
    create_benchmark: `${D}/benchmarking/create_benchmark`,
    upload_benchmark: `${D}/benchmarking/upload_benchmark`,

    // Read Benchmarks
    read_benchmarks_complete: `${D}/benchmarking/read_benchmarks_complete_by_ids`,
    read_benchmarks_core: `${D}/benchmarking/read_benchmarks_core`,
    get_benchmarks_sensitivity_level: `${D}/benchmarking/get_benchmarks_sensitivity_level`,
    get_benchmark_comparisons_for_benchmark: `${D}/benchmarking/get_benchmark_comparisons_for_benchmark`,

    // Update Benchmarks
    update_benchmarks_tpi_city: `${D}/benchmarking/update_benchmarks_tpi_city`,
    update_benchmarks_comparisons: `${D}/benchmarking/update_benchmarks_comparisons`,
    update_benchmarks_comparisons_anonymity: `${D}/benchmarking/update_benchmarks_comparisons_anonymity`,
    update_benchmarks_info_sensitivity_level: `${D}/benchmarking/update_benchmarks_info_sensitivity_level`,
    update_benchmarks_info_company_id: `${D}/benchmarking/update_benchmarks_info_company_id`,
    update_benchmarks_info_coordinates: `${D}/benchmarking/update_benchmarks_info_coordinates`,

    //Form Based Benchmarks
    update_form_based_benchmark: `${D}/benchmarking/update_form_based_benchmark`,

    // Delete Benchmark
    delete_benchmarks: `${D}/benchmarking/delete_benchmarks`,

    // CBS
    create_cbs: `${D}/benchmarking/create_cbs`,
    get_cbs: `${D}/benchmarking/read_cbs`,
    update_cbs_publish: `${D}/benchmarking/update_cbs_publish`,
    update_cbs: `${D}/benchmarking/update_cbs`,
    delete_cbs: `${D}/benchmarking/delete_cbs`,
    get_cbs_type_codes: `${D}/benchmarking/read_cbs_type_codes`,

    // CBS Elements
    add_element: `${D}/benchmarking/add_element`,
    edit_element: `${D}/benchmarking/edit_element`,
    move_element: `${D}/benchmarking/move_element`,
    delete_element: `${D}/benchmarking/delete_element`,

    // CBS Categories
    add_category_item: `${D}/benchmarking/add_category_item`,
    edit_category_item: `${D}/benchmarking/edit_category_item`,
    delete_category_item: `${D}/benchmarking/delete_category_item`,

    //BASIC BENCHMARK FORM API
    read_upload_form: `${D}/benchmarking/read_upload_form`,
    read_basic_benchmark_creation_fields: `${D}/benchmarking/read_basic_benchmark_creation_fields`,
    upload_basic_benchmark_elements: `${D}/benchmarking/upload_basic_benchmark_elements`,
    read_basic_benchmark_edit_fields: `${D}/benchmarking/read_basic_benchmark_edit_fields`,
    update_basic_benchmark_elements: `${D}/benchmarking/update_basic_benchmark_elements`,
    update_basic_benchmark_create_fields: `${D}/benchmarking/update_basic_benchmark_create_fields`,
    read_benchmark_notes_fileds: `${D}/benchmarking/read_benchmark_notes_fileds`,
    update_basic_benchmark_notes: `${D}/benchmarking/update_basic_benchmark_notes`,
    read_benchmark_metrics_fileds: `${D}/benchmarking/read_benchmark_metrics_fileds`,
    update_basic_benchmark_metrics: `${D}/benchmarking/update_basic_benchmark_metrics`,
    read_benchmark_edit_project_fileds: `${D}/benchmarking/read_benchmark_edit_project_fileds`,
    update_benchmark_edit_project_fields: `${D}/benchmarking/update_benchmark_edit_project_fields`,
  };
}
