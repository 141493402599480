import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "../modal/Modal";
import Spinner from "../common/Spinner";

import {
  useAggregatePackages,
  deleteAggregation,
} from "../../api/post-contract/PostContractPackageAPI";
import { AddEditPackageAggregation } from "./PackageAggregation.components";

PackageAggregationModal.propTypes = {
  projectId: PropTypes.string,
};

export function PackageAggregationModal(props) {
  const { projectId } = props;

  // Post Contract Packages
  const { allPackages } = useAggregatePackages(projectId);

  if (allPackages.loading) {
    return <Spinner width={50} />;
  }

  let aggregates = allPackages.data.filter(
    (pack) => pack.IsAggregation === true
  );

  const display = [];

  aggregates.forEach((pack) => {
    display.push(
      <div className="aggregate-item">
        <div className="aggregate-name">{pack.Name}</div>
        <Button
          text={"Edit Aggregate Package"}
          Component={AddEditPackageAggregation}
          projectId={projectId}
          allPackages={allPackages}
          selectedPackage={pack}
          elementClass={"aggregate-edit-button"}
          icon={<i className="fas fa-edit"></i>}
          tooltip={"Edit"}
        />
        <button
          id="delete-aggregate-button"
          className="clarification-delete-button"
          title="Delete"
          onClick={async () => {
            let data = {
              packageId: pack.PackageId,
              allPackages: allPackages,
              projectId: projectId,
            };
            await deleteAggregation(data);
          }}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
    );
  });

  return (
    <div className="pcf-container">
      <div className="general-row-container">
        <Button
          text={"Add Aggregate Package"}
          Component={AddEditPackageAggregation}
          projectId={projectId}
          allPackages={allPackages}
          selectedPackage={null}
          elementClass={"general-upload-button"}
          icon={"Create New"}
          tooltip={"Create New Aggregation"}
        />
      </div>
      <div className="aggregate-container">{display}</div>
    </div>
  );
}

Button.propTypes = {
  Component: PropTypes.object,
  tooltip: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.object,
  elementClass: PropTypes.string,
};

function Button(props) {
  const { Component, tooltip } = props;
  const [modal, setModal] = useState(false);
  const { text, icon, elementClass } = props;

  return (
    <div>
      <button
        id="package-aggregation-button"
        className={elementClass}
        title={tooltip}
        onClick={() => {
          setModal(true);
        }}
      >
        {icon}
      </button>
      <Modal
        // Modal Props
        title={text}
        Component={Component}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        {...props}
      />
    </div>
  );
}
