import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";

// API
import { bmAPIs } from "../api-endpoints/BenchmarkingEndpoints";
import isEmpty from "../../validation/is-empty";

export function useBasicBenchmarkForm(benchmarkFormType,benchmarkId) {
  const [loading, setLoading] = useState(true);
  const [basicBenchmarkForm, setBasicBenchmarkForm] = useState({});
  useEffect(() => {
    if (!isEmpty(benchmarkFormType)) {
      setLoading(true);
      getBasicBenchmarkForm(benchmarkFormType,benchmarkId)
        .then((data) => {
          setBasicBenchmarkForm(data);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [benchmarkFormType,benchmarkId]);

  return {
    formData: {
      data: basicBenchmarkForm?.data,
      loading: loading,
      get: getBasicBenchmarkForm,
      set: setBasicBenchmarkForm,
    },
  };
}

export async function getBasicBenchmarkForm(
  benchmarkFormType,benchmarkId ) {
  // BENCHMARKING API
  let url = "";
  if (benchmarkFormType === "basicBenchmarkCreationFields") {
    url = bmAPIs().read_basic_benchmark_creation_fields;
  }  
  if (benchmarkFormType === "basicBenchmarkEditProjectFields") {
    url = bmAPIs().read_benchmark_edit_project_fileds+
    "?benchmarkId=" + 
      benchmarkId ;
  }  

  if (benchmarkFormType === "elementEditForm") {
    url =
      bmAPIs().read_basic_benchmark_edit_fields +
      "?benchmarkId=" + 
      benchmarkId 
   }

  if (benchmarkFormType === "elementalUploadFields") {
    url =
      bmAPIs().read_basic_benchmark_creation_fields 
  }

  if (benchmarkFormType === "notesFields") {
    url =
      bmAPIs().read_benchmark_notes_fileds  + 
      "?benchmarkId=" + 
      benchmarkId ;
  }

  if (benchmarkFormType === "metricsFields") {
    url =
      bmAPIs().read_benchmark_metrics_fileds  + 
      "?benchmarkId=" + 
      benchmarkId ;
  }


  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("getBasicBenchmarkForm api error");
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log("getBasicBenchmarkForm response error");
  }
  return response;
}

export function updateBmFieldErrors(fields, setFieldErrors) {
  setFieldErrors([]);

  fields.forEach((field) => {
    if (field.isMandatory && field.value === "") {
      setFieldErrors((fieldErrors) => {
        fieldErrors.push(field.name);
        return fieldErrors;
      });
    }
  });
}

export async function updateBasicBenchmarkForm(data) {
  const { fields } = data;
  const { setErrors } = data;
  const { urlEndpoint } = data;
  const { setModal } = data;
  const { stateUpdateFunction } = data;
  const { setFieldErrors } = data;

  const formData = new FormData();
  let formFields = [];

  fields.forEach((field) => {
    if (field.dataType !== "file") {
      formFields.push(field);
    }

    if (field.dataType === "file") {
      formData.append("file", field.value);
    }
  });

  formData.append("field", JSON.stringify(formFields));

  setErrors({
    text: "Saving...",
    type: "feedback-success",
  });

  //BASIC BENCHMARK API
  let url = urlEndpoint;

  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("Upload Elemental Analysis Form Error");
  }

  if (response.ok) {
    await stateUpdateFunction();

    setErrors({
      text: "Updated Successfully",
      type: "feedback-success",
    });

    setModal(false);
  } else if (response.status === 422) {
    let errors = await response.json();
    setFieldErrors(errors.data);
    return setErrors({
      text: "Please Check Fields",
      type: "feedback-error",
    });
  } else {
    return setErrors({
      text: "Save Error",
      type: "feedback-error",
    });
  }
}


