import { detectDeployment } from "./Utilities";

export function upAPIs() {
  const config = {
    serviceAPI: true,
    localService: "https://dev2.cost-clarity.com/apis/userprofileservice/v1.0",
    deployedService: "/apis/userprofileservice/v1.0",
  };

  // Local: https://localhost:44301/v1.0
  // Deployed Dev: https://dev.cost-clarity.com/apis/userprofileservice/v1.0

  const D = detectDeployment(config);

  return {
    // Create User Profiles
    GetOrCreateUserProfile: `${D}/UserProfile/GetOrCreateUserProfile`,

    // Read User Profiles
    Get: `${D}/UserProfile/Get`,
    GetUserList: `${D}/UserProfile/GetUserList`,

    // Update User Profiles
    Put: `${D}/UserProfile/Put`,
    UploadImage: `${D}/UserProfile/UploadImage`,

    // Delete User Profile
    Delete: `${D}/UserProfile/Delete`,
  };
}
