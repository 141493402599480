import { detectDeployment } from "./Utilities";

export function localizationAPIs() {
  const config = {
    serviceAPI: true,
    localService: "https://dev2.cost-clarity.com/apis/localization/v1.0",
    deployedService: "/apis/localization/v1.0",
  };

  // Local dev "https://localhost:44380/v1.0"
  // Deployed dev "https://dev.cost-clarity.com/apis/localization/v1.0";
  // Deployed tst "https://test.cost-clarity.com/apis/localization/v1.0";
  // Deployed prd "https://www.cost-clarity.com/apis/localization/v1.0";

  const D = detectDeployment(config);

  return {
    // Create
    clone: `${D}/Localization/clone`,
    upload: `${D}/Localization/upload`,

    // Read
    get: `${D}/Localization`,
    get_all_namespaces: `${D}/Localization/namespace`,

    // Update
    update: `${D}/Localization/update`,

    // Delete
    delete: `${D}/Localization/namespace`,
  };
}
