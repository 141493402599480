import { detectDeployment } from "./Utilities";

export function opAPIs() {
  const config = {
    serviceAPI: true,
    localService:
      "https://dev2.cost-clarity.com/postcontract/apis/optioneeringservice/v1.0/Optioneering",
    deployedService: "/postcontract/apis/optioneeringservice/v1.0/Optioneering",
  };

  // Local dev "https://localhost:58083/v1.0/Optioneering"
  // Deployed dev "https://dev.cost-clarity.com/postcontract/apis/optioneeringservice/v1.0/Optioneering"

  const D = detectDeployment(config);

  return {
    // Optioneering
    get_stages: `${D}/options-analysis/get_stages`,
    create_stages: `${D}/options-analysis/create_stages`,
    add_items_to_stages: `${D}/options-analysis/add_items_to_stages`,
    upload_options_analysis: `${D}/options-analysis/upload_options_analysis`,

    upload_options_summary_breakdown_items: `${D}/options-analysis/upload_options_summary_breakdown_items`,
    delete_options_summary_breakdown_item: `${D}/options-analysis/delete_options_summary_breakdown_items`,
    save_baseline_costplan: `${D}/options-analysis/save_baseline_costplan`,
  };
}
