import { detectDeployment } from "./Utilities";

export function portAPIs() {
  const config = {
    serviceAPI: true,
    localService:
      "https://dev2.cost-clarity.com/postcontract/apis/portfolioservice/v1.0",
    deployedService: "/postcontract/apis/portfolioservice/v1.0",
  };

  // Local Service: 'https://localhost:44390/v1.0'
  // Deployed Service: 'https://dev.cost-clarity.com/postcontract/apis/portfolioservice/v1.0'

  const D = detectDeployment(config);

  return {
    // CREATE
    Post: `${D}/Portfolio`, // OK

    // READ
    GetPortfolioDetailByUser: `${D}/Portfolio/GetPortfolioDetailByUser`, // OK
    GetBenchmarkingPortfolioListByProject: `${D}/Portfolio/GetBenchmarkingPortfolioListByProject`, // OK
    Get: `${D}/Portfolio/Get`, // OK
    GetBenchmarkingPortfolioList: `${D}/Portfolio/GetBenchmarkingPortfolioList`, // OK
    // UPDATE
    Put: `${D}/Portfolio/Put`,
    UpdateProjectList: `${D}/Portfolio/UpdateProjectList`, // OK

    // DELETE
    Delete: `${D}/Portfolio`, // OK
  };
}
