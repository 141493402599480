// React
import React, { useState } from "react";
import { translate, tranStr } from "../../utils/translation";
import PropTypes from "prop-types";

import Clarifications from "../post-contract-commentary/Clarifications";
import Exclusions from "../post-contract-commentary/Exclusions";

import PostContractEditRisks from "../project-control-post-contract/PostContractEditRisks";
import ProgressClaimCommentary from "../post-contract-commentary/ProgressClaimCommentary.components";
import ReportModal from "../reports/ReportModal";
import { PackageAggregationModal } from "./PackageAggregationModal";

// Generic Components
import AdviceDecisionsEdit from "../dashboard-components/AdviceDecisionsEdit";
import Modal from "../modal/Modal";

import { ConfigurableForm } from "./PostContractForm";

// Functions
import { savePostContractDecisionsAndGlossary } from "../../api/post-contract/PostContractAdviceAPI";
import { hasRoles } from "../../utils/roles";
import { pc2APIs } from "../../api/api-endpoints/PostContract2.0Endpoints";
import isEmpty from "../../validation/is-empty";

PostContractPanel.propTypes = {
  user: PropTypes.object,
  postContract: PropTypes.object,
  selectedReport: PropTypes.object,
  selectedPackage: PropTypes.object,
  packages: PropTypes.array,
  postContractForm: PropTypes.object,
  project: PropTypes.object,
  postContractReports: PropTypes.array,
  setSelectedPackage: PropTypes.func,
};

export default function PostContractPanel(props) {
  const { user } = props;
  const { postContract } = props;
  const { selectedReport } = props;
  const { selectedPackage, setSelectedPackage } = props;
  const { packages } = props;
  const { postContractForm } = props;
  const { project } = props;
  const { postContractReports } = props;
  const [fields, setFields] = useState([]);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  //Check if the package has contract details, if they are NOT entered, disable the buttons
  let disableButtons = isEmpty(selectedPackage)
    ? true
    : !selectedPackage.HasContractFields;
  //Check if there are any post contract reports
  let postContractReportsEmpty = isEmpty(postContractReports)
    ? true
    : postContractReports.data.length === 0;

  let title = "";
  if (window.location.href.includes("dashboard")) {
    title = tranStr("Post Contract Control");
  }
  if (window.location.href.includes("Cost%20Report")) {
    title = tranStr("Cost Report Control");
  }
  if (window.location.href.includes("Progress%20Claim")) {
    title = tranStr("Progress Claim Control");
  }

  return (
    <div className="project-control-panel-controls">
      <h1 className="display-4">{title}</h1>
      <Button
        Component={ConfigurableForm}
        postContractFormType={"packageFields"}
        projectId={project.id}
        selectedPackage={selectedPackage}
        width={"670px"}
        icon={<i className="fas fa-file-contract" />}
        text="Contract Packages"
        showLocation={"dashboard"}
        stateUpdateFunction={async () =>
          await updatePackages(packages, project, setSelectedPackage)
        }
        urlEndpoint={pc2APIs().update_packages + "?projectId=" + project.id}
      />
      <Button
        Component={PackageAggregationModal}
        projectId={project.id}
        width={"670px"}
        icon={<i className="fas fa-th-large" />}
        text="Aggregate Packages"
        showLocation={"dashboard"}
      />
      <Button
        Component={ConfigurableForm}
        postContractFormType={"contractFields"}
        projectId={project.id}
        selectedPackage={selectedPackage}
        width={"670px"}
        icon={<i className="fas fa-bars" />}
        text="Contract Details"
        showLocation={"dashboard"}
        stateUpdateFunction={async () => {
          //Get and update the post contract reports
          packages.set(await packages.get(project.id));
          await contractFieldsUpdate(
            postContractReports,
            project,
            selectedPackage
          );
          const form = await postContractForm.get(
            "navigationFields", //on save, we will get the sections for navigation bar
            project.id,
            selectedPackage?.Id
          );
          postContractForm.set(form);
        }}
        urlEndpoint={
          pc2APIs().update_contract_fields +
          "?projectId=" +
          project.id +
          "&packageId=" +
          selectedPackage?.Id
        }
      />
      <Button
        Component={ConfigurableForm}
        postContractFormType={"sectionFields"}
        projectId={project.id}
        selectedPackage={selectedPackage}
        width={"670px"}
        icon={<i className="fas fa-eye-slash" />}
        text="Contract Sections"
        showLocation={"dashboard"}
        disabled={disableButtons}
        stateUpdateFunction={async () => {
          const form = await postContractForm.get(
            "navigationFields", //on save, we will get the sections for navigation bar
            project.id,
            selectedPackage?.Id
          );
          postContractForm.set(form);
          //check if needed packages.set(await packages.get(project.id));
        }}
        urlEndpoint={
          pc2APIs().update_section_fields +
          "?projectId=" +
          project.id +
          "&packageId=" +
          selectedPackage?.Id
        }
      />

      <Button
        Component={ReportModal}
        projectID={project.id}
        project={project}
        projectTitle={project.title}
        projectPhase={"POST_CONTRACT"}
        selectedReport={selectedReport}
        selectedPackage={selectedPackage}
        icon={<i className="fas fa-download" />}
        width={"1000px"}
        text={translate("Export Cost Report")}
        showLocation={"dashboard"}
        disabled={disableButtons || postContractReportsEmpty}
        type={"Cost Report"}
      />

      <Button
        Component={Clarifications}
        projectID={project.id}
        project={project}
        postContractId={selectedReport.Id}
        fields={fields}
        commentary={
          postContract.data[0]?.Advice.ExclusionsClarificationsProgress
        }
        setFields={setFields}
        width={"670px"}
        icon={<i className="fas fa-clipboard-list" />}
        text="Notes and Clarifications"
        postContract={postContract}
        showLocation={"Cost%20Report"}
        disabled={false}
      />

      <Button
        Component={Exclusions}
        projectID={project.id}
        project={project}
        postContractId={selectedReport.Id}
        fields={fields}
        commentary={
          postContract.data[0]?.Advice.ExclusionsClarificationsProgress
        }
        setFields={setFields}
        width={"670px"}
        icon={<i className="fas fa-clipboard-list" />}
        text="Exclusions"
        postContract={postContract}
        showLocation={"Cost%20Report"}
        disabled={false}
      />

      <Button
        Component={ProgressClaimCommentary}
        projectID={project.id}
        project={project}
        postContractId={selectedReport.Id}
        commentary={
          postContract.data[0]?.Advice.ExclusionsClarificationsProgress
        }
        width={"670px"}
        icon={<i className="fas fa-clipboard-list" />}
        text="Progress Commentary"
        postContract={postContract}
        showLocation={"Progress%20Claim"}
        disabled={false}
      />
      <Button
        Component={PostContractEditRisks}
        projectID={project.id}
        project={project}
        risks={postContract.data[0]?.Advice.Risks}
        postContractId={selectedReport.Id}
        postContract={postContract}
        icon={
          <i
            className="fas fa-exclamation-triangle"
            style={{ marginBottom: "3px" }}
          ></i>
        }
        text="Risks / Opportunities"
        showLocation={"Cost%20Report"}
        disabled={false}
      />
      <Button
        Component={AdviceDecisionsEdit}
        decisions={postContract.data[0]?.Advice.DecisionsGlossary}
        requiredData={postContract}
        saveFunction={savePostContractDecisionsAndGlossary}
        // POST CONTRACT API
        saveURL={pc2APIs().set_decisions}
        icon={<i className="fas fa-asterisk"></i>}
        text="Key Decisions"
        type={"decision"}
        fieldTypes={{ field1: "advice-decision", field2: "advice-required-by" }}
        labels={{ label1: "Decision", label2: "Required Date (DD/MM/YYYY)" }}
        note={
          "Add up to 5 key Decisions below to be displayed on the Post Contract Dashboard."
        }
        max={5}
        data={{
          projectID: project.id,
          postContractID: selectedReport.Id,
          postContract: postContract,
          type: "decision",
        }}
        showLocation={"Cost%20Report"}
        disabled={false}
      />

      <Button
        Component={AdviceDecisionsEdit}
        decisions={postContract.data[0]?.Advice.DecisionsGlossary}
        requiredData={postContract}
        saveFunction={savePostContractDecisionsAndGlossary}
        // POST CONTRACT API
        saveURL={pc2APIs().set_decisions}
        icon={<i className="fas fa-file-alt"></i>}
        text="Contractual Glossary"
        type={"glossary"}
        fieldTypes={{ field1: "advice-glossary", field2: "advice-glossary" }}
        labels={{
          label1: "Reporting Term",
          label2: "Project Specific or Contractual Term",
        }}
        note={
          "Use the fields below to clarify how reporting terms used in Cost Clarity relate to project specific and/or contractual terms."
        }
        max={20}
        data={{
          projectID: project.id,
          postContractID: selectedReport.Id,
          postContract: postContract,
          type: "glossary",
        }}
        showLocation={"Progress%20Claim"}
        disabled={false}
      />
    </div>
  );
}

Button.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  Component: PropTypes.func,
  showLocation: PropTypes.string,
  disabled: PropTypes.bool,
};

function Button(props) {
  const [modal, setModal] = useState(false);
  const { icon, text } = props;
  const { Component } = props;
  const { showLocation } = props;
  const { disabled } = props;

  if (!window.location.href.includes(showLocation)) {
    return null;
  }

  if (disabled) {
    return (
      <div className="project-control-panel-button-container">
        <div className={"project-control-panel-button-disabled"}>{icon}</div>
        <h1
          className="display-4"
          style={{ fontSize: "15px", marginBottom: "0px", marginLeft: "10px" }}
        >
          {text}
        </h1>
        <Modal
          // Modal Props
          title={text}
          Component={Component}
          modal={modal}
          setModal={setModal}
          // Component Props
          show={modal}
          setShow={setModal}
          {...props}
        />
        <span className="tooltiptext">{"Post Contract Details"}</span>
      </div>
    );
  }

  return (
    <div className="project-control-panel-button-container">
      <button
        className="project-control-panel-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {icon}
      </button>
      <h1
        className="display-4"
        style={{ fontSize: "15px", marginBottom: "0px", marginLeft: "10px" }}
      >
        {text}
      </h1>
      <Modal
        // Modal Props
        title={text}
        Component={Component}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        {...props}
      />
    </div>
  );
}

export async function contractFieldsUpdate(
  postContractReports,
  project,
  selectedPackage
) {
  const URL = window.location.href;
  const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
  postContractReports.set([]);
  postContractReports.set(
    await postContractReports.get(project.id, selectedPackage.Id, sheetName)
  );
}

export async function updatePackages(packages, project, setSelectedPackage) {
  //Reload the packages and set the selected package to the first one to make sure that if the selected package is deleted, the first one is selected from newly loaded set of packages
  packages.set(await packages.get(project.id));
  if (packages?.data.length > 0) {
    await setSelectedPackage(packages?.data[0]);
  }
}
