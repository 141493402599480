// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import {
  PortfolioInputSingle,
  PortfolioSelectSingle,
  PortfolioSelectSingleFilter,
  PortfolioInputDouble,
  BenchmarkModeToggle,
} from "./BenchmarkSelectionFilter.components";

import {
  sectorList,
  subsectorList,
  procurementList,
  typeList,
  qualityList,
  getCities,
  getCountries,
  getRegions,
} from "./BenchmarkSelectionFilter.functions";

import { tranStr } from "../../utils/translation";

import "./BenchmarkSelectionFilter.css";
import {
  BenchmarkInputDouble,
  BenchmarkInputSingle,
  BenchmarkSelectSingle,
  BenchmarkSelectSingleFilter,
} from "../benchmarking/Benchmark.components";
import { useLocations } from "../../api/admin/RegionManagementAPI";

/// props validation | SQ(javascript:S6774)
BenchmarkSelectionFilter.propTypes = {
  setFilters: PropTypes.func,
  globalBenchmark: PropTypes.bool,
  disableQuickBenchmarks: PropTypes.bool,
  sortedBaseYear: PropTypes.array,
};
///
export default function BenchmarkSelectionFilter(props) {
  const { setFilters } = props;
  const { globalBenchmark } = props;
  const { disableQuickBenchmarks } = props;
  const { sortedBaseYear } = props;

  //API
  const { locations } = useLocations(true);

  // Filter Inputs
  const [title, setTitle] = useState("");
  const [standard, setStandard] = useState("");
  const [clientCompany, setClientCompany] = useState("");
  const [architect, setArchitect] = useState("");
  const [sector, setSector] = useState("");
  const [subsector, setSubsector] = useState("");
  const [projectType, setProjectType] = useState("");
  const [projectQuality, setProjectQuality] = useState("");
  const [projectStage, setProjectStage] = useState("");
  const [procurementMethod, setProcurementMethod] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [tpiLocation, setTpiLocation] = useState("");
  const [unionStatus, setUnionStatus] = useState("");
  const [contractor, setContractor] = useState("");

  const [minGFA, setMinGFA] = useState("");
  const [maxGFA, setMaxGFA] = useState("");

  const [minConstructionCost, setMinConstructionCost] = useState("");
  const [maxConstructionCost, setMaxConstructionCost] = useState("");

  const [minProjectCost, setMinProjectCost] = useState("");
  const [maxProjectCost, setMaxProjectCost] = useState("");

  const [minConstructionRate, setMinConstructionRate] = useState("");
  const [maxConstructionRate, setMaxConstructionRate] = useState("");

  const [isQuickBenchmark, setIsQuickBenchmark] = useState(false);
  const [basis, setBasis] = useState("");
  const [baseYear, setBaseYear] = useState("");

  useEffect(() => {
    setFilters({
      title: title.toLowerCase(),
      standard: standard.toLowerCase(),
      clientCompany: clientCompany.toLowerCase(),
      architect: architect.toLowerCase(),
      sector: sector.toLowerCase(),
      subsector: subsector.toLowerCase(),
      projectType: projectType.toLowerCase(),
      projectQuality: projectQuality.toLowerCase(),
      projectStage: projectStage.toLowerCase(),
      procurementMethod: procurementMethod.toLowerCase(),
      // location: location.toLowerCase(),
      minGFA: parseInt(minGFA) || 0,
      maxGFA: parseInt(maxGFA) || Infinity,
      minConstructionCost: parseInt(minConstructionCost) || 0,
      maxConstructionCost: parseInt(maxConstructionCost) || Infinity,
      minProjectCost: parseInt(minProjectCost) || 0,
      maxProjectCost: parseInt(maxProjectCost) || Infinity,
      minConstructionRate: parseInt(minConstructionRate) || 0,
      maxConstructionRate: parseInt(maxConstructionRate) || Infinity,
      region: region.toLowerCase(),
      country: country.toLowerCase(),
      city: city.toLowerCase(),
      tpiLocation: tpiLocation.toLowerCase(),
      unionStatus: unionStatus.toLowerCase(),
      contractor: contractor.toLowerCase(),
      isQuickBenchmark: isQuickBenchmark,
      basis: basis.toLowerCase(),
      baseYear: parseInt(baseYear) || 1900,
    });
  }, [
    setFilters,
    title,
    standard,
    clientCompany,
    architect,
    sector,
    subsector,
    projectType,
    projectQuality,
    projectStage,
    procurementMethod,
    //  location,
    minGFA,
    maxGFA,
    minConstructionCost,
    maxConstructionCost,
    minProjectCost,
    maxProjectCost,
    minConstructionRate,
    maxConstructionRate,
    region,
    country,
    city,
    tpiLocation,
    unionStatus,
    contractor,
    isQuickBenchmark,
    basis,
    baseYear,
  ]);

  const regionsToLoad = getRegions(locations);

  const countriesToLoad = getCountries(locations, region);

  const citiesToLoad = getCities(locations, region, country);

  return (
    <>
      <div className="benchmark-selection-filter-container">
        {!globalBenchmark && (
          <div id="BenchMarkingFilter">
            <PortfolioInputSingle
              placeholder={tranStr("Project Title")}
              value={title}
              onChange={setTitle}
              icon={<i className="fas fa-pen-fancy"></i>}
            />
            <PortfolioInputSingle
              placeholder={tranStr("CBS")}
              value={standard}
              onChange={setStandard}
              icon={<i className="fas fa-align-left"></i>}
            />
            <PortfolioInputSingle
              placeholder={tranStr("Client Company")}
              value={clientCompany}
              onChange={setClientCompany}
              icon={<i className="far fa-building"></i>}
            />
            <PortfolioInputSingle
              placeholder={tranStr("Architect Designer")}
              value={architect}
              onChange={setArchitect}
              icon={<i className="fas fa-edit"></i>}
            />
            <PortfolioSelectSingle
              placeholder={tranStr("Sector")}
              value={sector}
              onChange={setSector}
              setChildSelection={setSubsector}
              options={sectorList()}
              icon={<i className="fas fa-th-large" />}
            />
            <PortfolioSelectSingleFilter
              placeholder={tranStr("Subsector")}
              value={subsector}
              onChange={setSubsector}
              options={subsectorList()}
              filter={sector}
              icon={<i className="fas fa-th" />}
            />
            <PortfolioSelectSingle
              placeholder={tranStr("Project Type")}
              value={projectType}
              onChange={setProjectType}
              options={typeList()}
              icon={<i className="fas fa-shapes"></i>}
            />

            <PortfolioSelectSingle
              placeholder={tranStr("Project Quality")}
              value={projectQuality}
              onChange={setProjectQuality}
              options={qualityList()}
              icon={<i className="fas fa-gem"></i>}
            />
            <PortfolioInputSingle
              placeholder={tranStr("Project Stage")}
              value={projectStage}
              onChange={setProjectStage}
              icon={<i className="fas fa-bars"></i>}
            />
            <PortfolioSelectSingle
              placeholder={tranStr("Procurement Method")}
              value={procurementMethod}
              onChange={setProcurementMethod}
              options={procurementList()}
              icon={<i className="fas fa-project-diagram"></i>}
            />
            <PortfolioSelectSingle
              placeholder={tranStr("Beyond Base Date of")}
              value={baseYear}
              onChange={setBaseYear}
              options={sortedBaseYear}
              icon={<i className="far fa-calendar"></i>}
            />
            <PortfolioSelectSingle
              placeholder={tranStr("Region")}
              value={region}
              onChange={setRegion}
              options={regionsToLoad}
              icon={<i className="far fa-calendar"></i>}
            />

            <PortfolioSelectSingle
              placeholder={tranStr("Country")}
              value={country}
              onChange={setCountry}
              options={countriesToLoad}
              icon={<i className="fas fa-flag"></i>}
            />
            <PortfolioSelectSingle
              placeholder={tranStr("City")}
              value={city}
              onChange={setCity}
              options={citiesToLoad}
              icon={<i className="fas fa-city"></i>}
            />

            <PortfolioInputSingle
              placeholder={tranStr("TPI Location")}
              value={tpiLocation}
              onChange={setTpiLocation}
              icon={<i className="fas fa-map-marker-alt"></i>}
            />
            <PortfolioInputSingle
              placeholder={tranStr("Contractor")}
              value={contractor}
              onChange={setContractor}
              icon={<i className="fas fa-male"></i>}
            />
            <PortfolioInputSingle
              placeholder={tranStr("Union / Non-Union")}
              value={unionStatus}
              onChange={setUnionStatus}
              icon={<i className="fas fa-layer-group"></i>}
            />

            <PortfolioInputSingle
              placeholder={tranStr("Source")}
              value={basis}
              onChange={setBasis}
              icon={<i className="fas fa-external-link-alt"></i>}
            />
            <PortfolioInputDouble
              placeholder={tranStr("Local Region Area")}
              value1={minGFA}
              onChange1={setMinGFA}
              value2={maxGFA}
              onChange2={setMaxGFA}
              icon={<i className="fas fa-ruler-combined" />}
            />
            <PortfolioInputDouble
              placeholder={tranStr("Construction Cost")}
              value1={minConstructionCost}
              onChange1={setMinConstructionCost}
              value2={maxConstructionCost}
              onChange2={setMaxConstructionCost}
              icon={<i className="fas fa-hammer"></i>}
            />
            <PortfolioInputDouble
              placeholder={tranStr("Project Cost")}
              value1={minProjectCost}
              onChange1={setMinProjectCost}
              value2={maxProjectCost}
              onChange2={setMaxProjectCost}
              icon={<i className="fas fa-drafting-compass"></i>}
            />

            <PortfolioInputDouble
              placeholder={tranStr("Construction Cost / Area")}
              value1={minConstructionRate}
              onChange1={setMinConstructionRate}
              value2={maxConstructionRate}
              onChange2={setMaxConstructionRate}
              icon={<i className="fas fa-pencil-ruler"></i>}
            />

            {!disableQuickBenchmarks && (
              <BenchmarkModeToggle
                placeholder={tranStr("Quick Benchmarks Visible")}
                value={isQuickBenchmark}
                onChange={setIsQuickBenchmark}
                icon={<i className="fas fa-toggle-on"></i>}
              />
            )}
          </div>
        )}
      </div>
      {globalBenchmark && (
        <div
          id="BenchMarkingFilter"
          className="benchmark-advanced-search-flex-scroll"
        >
          <BenchmarkInputSingle
            placeholder={tranStr("Project Title")}
            value={title}
            onChange={setTitle}
            icon={<i className="fas fa-pen-fancy"></i>}
          />
          <BenchmarkInputSingle
            placeholder={tranStr("CBS")}
            value={standard}
            onChange={setStandard}
            icon={<i className="fas fa-align-left"></i>}
          />
          <BenchmarkInputSingle
            placeholder={tranStr("Client Company")}
            value={clientCompany}
            onChange={setClientCompany}
            icon={<i className="far fa-building"></i>}
          />
          <BenchmarkInputSingle
            placeholder={tranStr("Architect Designer")}
            value={architect}
            onChange={setArchitect}
            icon={<i className="fas fa-edit"></i>}
          />
          <BenchmarkSelectSingle
            placeholder={tranStr("Sector")}
            value={sector}
            onChange={setSector}
            setChildSelection={setSubsector}
            values={sectorList()}
            icon={<i className="fas fa-th-large" />}
          />
          <BenchmarkSelectSingleFilter
            placeholder={tranStr("Subsector")}
            value={subsector}
            onChange={setSubsector}
            values={subsectorList()}
            filter={sector}
            icon={<i className="fas fa-th" />}
          />
          <BenchmarkSelectSingle
            placeholder={tranStr("Project Type")}
            value={projectType}
            onChange={setProjectType}
            values={typeList()}
            icon={<i className="fas fa-shapes"></i>}
          />

          <BenchmarkSelectSingle
            placeholder={tranStr("Project Quality")}
            value={projectQuality}
            onChange={setProjectQuality}
            values={qualityList()}
            icon={<i className="fas fa-gem"></i>}
          />
          <BenchmarkInputSingle
            placeholder={tranStr("Project Stage")}
            value={projectStage}
            onChange={setProjectStage}
            icon={<i className="fas fa-bars"></i>}
          />
          <BenchmarkSelectSingle
            placeholder={tranStr("Procurement Method")}
            value={procurementMethod}
            onChange={setProcurementMethod}
            values={procurementList()}
            icon={<i className="fas fa-project-diagram"></i>}
          />
          <BenchmarkSelectSingle
            placeholder={tranStr("Beyond Base Date of")}
            value={baseYear}
            onChange={setBaseYear}
            values={sortedBaseYear}
            icon={<i className="far fa-calendar"></i>}
          />
          <BenchmarkInputSingle
            placeholder={tranStr("Region")}
            value={region}
            onChange={setRegion}
            values={regionsToLoad}
            icon={<i className="fas fa-globe-asia"></i>}
          />
          <BenchmarkSelectSingle
            placeholder={tranStr("Country")}
            value={country}
            onChange={setCountry}
            values={countriesToLoad}
            icon={<i className="fas fa-flag"></i>}
          />
          <BenchmarkSelectSingle
            placeholder={tranStr("City")}
            value={city}
            onChange={setCity}
            values={citiesToLoad}
            icon={<i className="fas fa-city"></i>}
          />

          <BenchmarkInputSingle
            placeholder={tranStr("TPI Location")}
            value={tpiLocation}
            onChange={setTpiLocation}
            icon={<i className="fas fa-map-marker-alt"></i>}
          />
          <BenchmarkInputSingle
            placeholder={tranStr("Contractor")}
            value={contractor}
            onChange={setContractor}
            icon={<i className="fas fa-male"></i>}
          />
          <BenchmarkInputSingle
            placeholder={tranStr("Union / Non-Union")}
            value={unionStatus}
            onChange={setUnionStatus}
            icon={<i className="fas fa-layer-group"></i>}
          />

          <BenchmarkInputSingle
            placeholder={tranStr("Source")}
            value={basis}
            onChange={setBasis}
            icon={<i className="fas fa-external-link-alt"></i>}
          />
          <BenchmarkInputDouble
            placeholder={tranStr("Local Region Area")}
            value1={minGFA}
            onChange1={setMinGFA}
            value2={maxGFA}
            onChange2={setMaxGFA}
            icon={<i className="fas fa-ruler-combined" />}
          />
          <BenchmarkInputDouble
            placeholder={tranStr("Construction Cost")}
            value1={minConstructionCost}
            onChange1={setMinConstructionCost}
            value2={maxConstructionCost}
            onChange2={setMaxConstructionCost}
            icon={<i className="fas fa-hammer"></i>}
          />
          <BenchmarkInputDouble
            placeholder={tranStr("Project Cost")}
            value1={minProjectCost}
            onChange1={setMinProjectCost}
            value2={maxProjectCost}
            onChange2={setMaxProjectCost}
            icon={<i className="fas fa-drafting-compass"></i>}
          />

          <BenchmarkInputDouble
            placeholder={tranStr("Construction Cost / Area")}
            value1={minConstructionRate}
            onChange1={setMinConstructionRate}
            value2={maxConstructionRate}
            onChange2={setMaxConstructionRate}
            icon={<i className="fas fa-pencil-ruler"></i>}
          />
        </div>
      )}
    </>
  );
}
