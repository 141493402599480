import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import { AutoCloseModal } from "../modal/Modal";

export async function onSubmit(data) {
  if (isEmpty(data.CPs.data)) {
    return data.setError({
      text: tranStr("Please configure Cost Plan Stages"),
      type: "feedback-error",
    });
  }

  if (data.file.length < 1) {
    return data.setError({
      text: tranStr("Please attach a File"),
      type: "feedback-error",
    });
  }

  if (data.standard === "") {
    return data.setError({
      text: tranStr("Please select CBS"),
      type: "feedback-error",
    });
  }

  data.setError({
    text: tranStr("Uploading..."),
    type: "feedback-success",
  });

  const { project } = data;

  const payload = {
    project_id: data.projectID,
    cost_plan_id: data.cpId,
    standard: data.standard,
    stage: data.stage,
    revision: data.revision,
    date: data.date,
    file_count: data.file.length,
    part: data.part,
    design_contingency_code: data.designContingencyCode,
    tax_code: data.taxCode,

    // Project Data
    company_id: project.company_id,
    city: project.city,
    country: project.country,
    currency: project.currency,
    measurement_system: project.measurement_system,
    procurement: project.procurement,
    project_title: project.title,
    job_number: project.job_number,
    project_type: project.type,
    quality: project.quality,
    region: project.region,
    sector: project.sector,
    sub_sector: project.sub_sector,
    tpi_city: project.tpi_city,
  };

  const formData = new FormData();
  const field = JSON.stringify(payload);
  formData.append("field", field);

  for (let i = 0; i < data.file.length; i++) {
    formData.append("file" + i, data.file[i]);
  }

  let url = cpAPIs().upload_estimate;

  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({
      authJson: false,
      authForm: true,
      guestJson: false,
    }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
    // Update Cost Plan
    let cp = await data.CPs.get(data.projectID, data.project);
    data.CPs.set(cp);

    data.setMessages(response);
    data.setError({
      text: tranStr("Upload Complete"),
      type: "feedback-success",
    });

    if (response.filter((item) => item.status === "rejected").length === 0) {
      // close modal after timeout
      data.setMessages(response);
      AutoCloseModal(data.setShow);
    } else {
      data.setMessages(response);
      data.setError({
        text: tranStr("Upload Failed"),
        type: "feedback-error",
      });
    }

    return response;
  } else {
    console.log("Error in Upload");
    data.setMessages(response);
    data.setError({
      text: tranStr("Upload Failed"),
      type: "feedback-error",
    });
    return [];
  }
}

// Find the largest number in an array
export function maxNumber(array, field) {
  let tempTotal = 0;
  tempTotal = Math.max.apply(
    Math,
    array.map(function (o) {
      return o[field];
    })
  );

  if (!isFinite(tempTotal)) {
    tempTotal = 0;
  }

  return tempTotal;
}

// Find the highest part number out of the estimates available for a selection
export function calculatePartNumber(CPs, stage, revision) {
  let part = 0;

  // Within this stage
  CPs.data.forEach((CPStage) => {
    // Within this revision
    CPStage.versions.forEach((CP) => {
      if (
        parseInt(CP.stage) === parseInt(stage) &&
        parseInt(CP.version) === parseInt(revision)
      ) {
        // Find the highest part number available
        part = maxNumber(CP.estimates, "part");
      }
    });
  });

  return parseInt(part);
}

// Find the highest part number out of the estimates available for a selection
export function calculateVersionNumber(CPs, stage) {
  let revision = 1;

  // Within this stage
  CPs.data.forEach((CPStage) => {
    // Within this revision
    CPStage.versions.forEach((CP) => {
      if (parseInt(CP.stage) === parseInt(stage)) {
        // Find the highest revision number available
        let tempRevision = maxNumber(CP.estimates, "revision");
        // If 1 increment to 2 and so on
        if (tempRevision >= revision) {
          revision = tempRevision + 1;
        }
      }
    });
  });

  return parseInt(revision);
}
